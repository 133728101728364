<form [formGroup]="form" (submit)="submit()">
  <app-error></app-error>

  <label class="form-title-field" appFormClass="name">
    Naziv živila:
    <span class="help-tooltip" tooltip="Vključite naziv živila, ki bo celovit in bo prikazoval njegove ključne lastnosti (npr. Sadni jogurt - jagoda, 3,5 % m.m.). Izogibajte se presplošnim nazivom živil, kot so: jogurt, kruh, marmelada..., saj jih boste kasneje v bazi živil težko našli."></span>
    <input type="text" formControlName="name">
  </label>

  <div class="grid1">
    <label class="form-group" appFormClass="producer">
      <span class="form-label">
        Proizvajalec
        <span class="help-tooltip" tooltip="Vključite naziv proizvajalca (npr. Kmetija Janko, Metka ali Pekarna Dobrovoljc ipd.)"></span>
      </span>
      <div class="textarea-resizable">
        <textarea #producer type="text" formControlName="producer" rows="1"></textarea>
      </div>
    </label>
    <label class="form-group" appFormClass="ingredients">
      <div class="form-label">
        Sestavine
        <span class="help-tooltip" tooltip="Prepričajte se, da so pri posameznih živilih opredeljene vse sestavine, ki jih na označbi določa proizvajalec. „Sestavina“     pomeni     vsako     snov     ali     proizvod,     vključno   z   aromami,   aditivi   za   živila   in   encimi   za   živila,   ter   vsako   sestavino   v   sestavljenih   sestavinah,   ki   se   uporabljajo   v   proizvodnji  ali  pri  pripravi  živila  in  so  še  vedno  prisotni  v   končnem   proizvodu,   tudi   če   so   v   spremenjeni   obliki;   ostanki  se  ne  štejejo  za  „sestavine“."></span>
      </div>
      <div class="textarea-resizable">
        <textarea #ingredients type="text" formControlName="ingredients" class="light-control" rows="2"></textarea>
      </div>
    </label>
    <label class="form-group edible" appFormClass="edible">
      <div class="form-label">
        Kalo
        <span class="help-tooltip" tooltip="Kalo je izguba oziroma uničenje blaga, ki nastane zaradi lastnosti blaga s sušenjem, krčenjem, izhlapevanjem in se kaže na teži, obsegu ali površini blaga. "></span>
      </div>
      <div class="edible-list">
        <span class="ratio" [class.ratio-selected]="form.value.edible === 0" (click)="setEdible(0)">0%</span>
        <span class="ratio" [class.ratio-selected]="form.value.edible === 1" (click)="setEdible(1)">1%</span>
        <span class="ratio" [class.ratio-selected]="form.value.edible === 2" (click)="setEdible(2)">2%</span>
        <span class="ratio" [class.ratio-selected]="form.value.edible === 5" (click)="setEdible(5)">5%</span>
        <span class="ratio" [class.ratio-selected]="form.value.edible === 10" (click)="setEdible(10)">10%</span>
        <div class="ratio--custom">
          <input type="number" formControlName="edible">
        </div>
      </div>
    </label>
    <div class="grid2">
      <div>
        <label class="form-group supplier" appFormClass="supplier">
          <span class="form-label">
            Dobavitelj
            <span class="help-tooltip" tooltip="Opredelite dobavitelja. Opredelitev je še posebej pomembna v zavodih, kjer imate več možnih dobaviteljev."></span>
          </span>
          <div class="textarea-resizable">
            <textarea #supplier type="text" formControlName="supplier" rows="1"></textarea>
          </div>
        </label>
        <div class="form-group ecatalog-type">
          <div class="form-label" appFormClass="eCatalog">
            Skupina živil
            <span class="help-tooltip" tooltip="Opredelite v katero skupino sodi živilo. Podatek omogoča, da vam e-orodje upoštevalo frekvenco pojavljanja živil v jedilnikih, skladno s smernicami."></span>
          </div>
          <app-ecatalog [values]="values" formControlName="eCatalog"></app-ecatalog>
        </div>
        <div class="form-group" appFormClass="qualityScheme">
          <div class="form-label">
            Sheme kakovosti
            <span class="help-tooltip" tooltip="Sheme kakovosti so namenjene kmetijskim pridelkom in živilom s posebnimi lastnostmi oziroma značilnostmi, ki so lahko posledica vpliva geografskega območja, načina pridelave ali predelave, tradicionalnih receptov ali postopkov ali pa presegajo predpisano kakovost, pogoje glede varstva zdravja ljudi, živali ali rastlin, dobrega počutja živali ali zaščite okolja. "></span>
          </div>
          <app-checkbox-array formControlName="qualityScheme" [options]="qualitySchemeOptions"></app-checkbox-array>
        </div>
      </div>
      <div class="form-group" appFormClass="allergens">
        <div class="form-label">
          Alergeni
          <span class="help-tooltip" tooltip="Pred potrditvijo živil skrbno preverite vsebnost alergenov in alergenov v sledovih na označbah, ki so določene s strani proizvajalca živila! NIJZ ne prevzema odgovornosti za neskladnost označbe alergenov v bazi živil."></span>
          <span class="allergens-contains">vsebuje</span>
          <span class="allergens-traces">v sledeh</span>
        </div>
        <div class="allergens-list" formArrayName="allergens">
          <label *ngFor="let allergen of allergenOptions; let index=index">
            <app-checkbox [formControlName]="index" [indeterminate]="true"></app-checkbox>
            <span [class.allergen-checked]="form.value.allergens[index]">{{ allergen.title }}</span>
          </label>
        </div>
      </div>
    </div>
    <div>
      <div class="packaging-array" formArrayName="packaging">
        <div class="form-label">
          Pakiranja
          <span class="help-tooltip" tooltip="Orodje omogoča vnos različnih pakiranj živila v gramih (npr. maslo 2 g, 1000 g, polnomastno mleko 2,5 dl ≈ 255 g). &#10;Poskrbite, da pravilno pretvorite enote, če je to potrebno"></span>
        </div>
        <div *ngFor="let _ of packaging.controls; let packageIndex = index" class="package">
          <ng-container [formGroupName]="packageIndex">
            <label appFormClass="amount">Neto količina</label>
            <div class="text package--amount">
              <input type="number" formControlName="amount" min="0">
              g
            </div>
            <label appFormClass="barcode">Črtna koda</label>
            <div class="text package--barcode">
              <input type="text" formControlName="barcode">
            </div>
            <label appFormClass="basePrice">Cena brez DDV</label>
            <div class="text">
              <input type="number" formControlName="basePrice" min="0">
              EUR
            </div>
            <label>+ DDV</label>
            <div class="radio package--tax" appFormClass="tax">
              <label>
                <input type="radio" [value]="9.5" formControlName="tax">
                <span>9,5%</span>
              </label>
              <label>
                <input type="radio" [value]="22" formControlName="tax">
                <span>22%</span>
              </label>
            </div>
            <label>+ odbitni delež</label>
            <div class="radio" appFormClass="deductable">
              <label>
                <input type="radio" [value]="0" formControlName="deductable">
                <span>0%</span>
              </label>
              <label>
                <input type="radio" [value]="1" formControlName="deductable">
                <span>1%</span>
              </label>
              <label>
                <input type="radio" [value]="2" formControlName="deductable">
                <span>2%</span>
              </label>
              <label>
                <input type="radio" [value]="3" formControlName="deductable">
                <span>3%</span>
              </label>
            </div>
            <label>status</label>
            <div class="radio">
              <label>
                <input type="radio" [value]="true" formControlName="active">
                <span>aktiven</span>
              </label>
              <label>
                <input type="radio" [value]="false" formControlName="active">
                <span>neaktiven</span>
              </label>
            </div>
            <button class="delete-package" type="button" (click)="removePackage(packageIndex)">Izbriši pakiranje</button>
          </ng-container>
        </div>
        <button type="button" class="btn-add-horizontal" (click)="addPackage()">Dodaj novo pakiranje</button>
      </div>
      <div>
        <div class="form-label">
          Hranilne vrednosti
          <span class="help-tooltip" tooltip="Obvezna označba hranilne vrednosti vključuje: energijsko vrednost, količine maščob, nasičenih maščob, ogljikovih hidratov, sladkorjev, beljakovin in soli."></span>
        </div>
        <table class="values">
          <thead>
          <tr>
            <th></th>
            <th>na 100 g</th>
          </tr>
          </thead>
          <tbody formArrayName="specifiedValues">
          <tr *ngFor="let value of specifiedValues.controls; let valueIndex = index">
            <ng-container [formGroupName]="valueIndex">
              <th>
                <label for="value_{{ form.value.specifiedValues[valueIndex].component }}" appFormClass="value" [class.values-indent]="COMPONENT_DEFAULTS.get(form.value.specifiedValues[valueIndex].component).indent">
                  {{ getComponentLabel(valueIndex) }}
                </label>
              </th>
              <td>
                <div>
                  <input type="number" formControlName="value" id="value_{{ form.value.specifiedValues[valueIndex].component }}" step="0.1" min="0">
                  {{ COMPONENT_DEFAULTS.get(form.value.specifiedValues[valueIndex].component).unit }}
                </div>
              </td>
            </ng-container>
          </tr>
          </tbody>
        </table>
      </div>
      <app-corner-buttons>
        <ng-content></ng-content>
      </app-corner-buttons>
    </div>
  </div>
</form>
